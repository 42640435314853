import { useAccount, useContractRead, useContractWrite } from "wagmi";
import "./App.css";
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Text,
  Input,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { contractData, fromWei } from "./utils/utils";
import { useEffect, useState } from "react";
import Packages from "./components/Packages";
import { toast } from "react-toastify";
import { parseEther, formatEther } from "viem";
import axios from "axios";

function App() {
  const { address, isConnected } = useAccount();
  const [lkdPrice, setLkdPrice] = useState(0);

  const [formData, setFormData] = useState({
    amount: "",
  });
  const [validation, setValidation] = useState({
    isUsdtApproved: false,
    isLkdApproved: false,
    isStakedComplete: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const { data: usdtRationInvestment }: any = useContractRead({
    abi: contractData?.linkdaoStakingABI,
    address: contractData?.linkdaoStakingAddress as `0x${string}`,
    functionName: "USDT_RATION_INVESTMNET",
    args: [],
    watch: true,
    select: (data: any) => data?.toString(),
  });

  const { data: getLinkdaoPrice }: any = useContractRead({
    abi: contractData?.linkdaoStakingABI,
    address: contractData?.linkdaoStakingAddress as `0x${string}`,
    functionName: "getLinkdaoPrice",
    args: [],
    watch: true,
    select: (data: any) => data?.toString(),
  });

  const calculateLkdValue = () => {
    const usdtExchangeRate = parseFloat(lkdPrice?.toString());
    let eightyPer = Number(formData?.amount) * 0.8;
    return Number(eightyPer) / usdtExchangeRate;
  };

  const calculateUSDTApprove = () => {
    const USDT_PERC = usdtRationInvestment / 10000;
    const tempAmount = (USDT_PERC * Number(formData?.amount)).toString();
    return Number(tempAmount) || 0;
  };

  const { data: withdrawableAmount }: any = useContractRead({
    abi: contractData?.linkdaoStakingABI,
    address: contractData?.linkdaoStakingAddress as `0x${string}`,
    functionName: "getWithdrawableTotalProfit",
    args: [address],
    watch: true,
    select: (data: any) => Number(formatEther(data?.toString())),
  });

  const { data: usdtAllowance }: any = useContractRead({
    abi: contractData?.usdtContractAbi,
    address: contractData?.usdtContractAddress as `0x${string}`,
    functionName: "allowance",
    args: [address, contractData?.linkdaoStakingAddress],
    watch: true,
    select: (data: any) => Number(formatEther(data?.toString())),
  });

  const { data: lkdBalance, isLoading: lkdLoading } = useContractRead({
    address: contractData?.lkdContractAddress as `0x${string}`,
    abi: contractData?.lkdContractAbi,
    functionName: "balanceOf",
    args: [address],
  });
  const { data: usdtBalance, isLoading: usdtBnlcLoading } = useContractRead({
    abi: contractData?.usdtContractAbi,
    address: contractData?.usdtContractAddress as `0x${string}`,
    functionName: "balanceOf",
    args: [address],
  });

  const { data: lkdAllowance }: any = useContractRead({
    abi: contractData?.lkdContractAbi,
    address: contractData?.lkdContractAddress as `0x${string}`,
    functionName: "allowance",
    args: [address, contractData?.linkdaoStakingAddress],
    watch: true,
    select: (data: any) => Number(formatEther(data?.toString())),
  });

  const { writeAsync: approveUsdt, isLoading: isApproveUsdtLoading }: any =
    useContractWrite({
      abi: contractData?.usdtContractAbi,
      address: contractData?.usdtContractAddress as `0x${string}`,
      functionName: "approve", // Replace with the actual function name for approval
      args: [
        contractData?.linkdaoStakingAddress,
        // parseEther(calculateUSDTApprove()?.toString()),
        // max allowance
        "115792089237316195423570985008687907853269984665640564039457584007913129639935",
      ],
      onSuccess() {
        toast.success("USDT Approved");
        setIsLoading(false);
        setValidation({
          ...validation,
          isUsdtApproved: true,
        });
      },
      onError() {
        setIsLoading(false);
      },
    });

  const { writeAsync: approveLKD, isLoading: isApproveLkdLoading }: any =
    useContractWrite({
      abi: contractData?.lkdContractAbi,
      address: contractData?.lkdContractAddress as `0x${string}`,
      functionName: "approve", // Replace with the actual function name for approval
      args: [
        contractData?.linkdaoStakingAddress,
        // parseEther((calculateLkdValue() - calculateUSDTApprove()).toString()),
        "115792089237316195423570985008687907853269984665640564039457584007913129639935",
      ],
      onSuccess() {
        toast.success("LKD Approved");
        setIsLoading(false);

        setValidation({
          ...validation,
          isLkdApproved: true,
        });
      },
      onError() {
        setIsLoading(false);
      },
    });

  const { writeAsync: stake, isLoading: isStakeLoading }: any =
    useContractWrite({
      abi: contractData?.linkdaoStakingABI,
      address: contractData?.linkdaoStakingAddress as `0x${string}`,
      functionName: "investAmount",
      args: [parseEther(formData?.amount?.toString())],
      onSuccess() {
        toast.success("Staked successfully");
        setValidation({
          ...validation,
          isStakedComplete: true,
        });
        setFormData({
          amount: "",
        });
      },
      onError() {
        setIsLoading(false);
      },
    });

  const { writeAsync: withdraw, isLoading: isWithdrawLoading }: any =
    useContractWrite({
      abi: contractData?.linkdaoStakingABI,
      address: contractData?.linkdaoStakingAddress as `0x${string}`,
      functionName: "withdrawReward",
      args: [],
      onSuccess() {},
      onError() {
        setIsLoading(false);
      },
    });

  const getLkdPrice = async () => {
    let { data } = await axios.get("https://price.linkdao.cc/api/tokenPrice");
    setLkdPrice(data?.data?.price || 0);
  };

  useEffect(() => {
    getLkdPrice();
  }, []);

  return (
    <Box className="App">
      <Flex
        justify="center"
        align="center"
        minH="60vh"
        py="50px"
        px={{ base: "20px" }}
      >
        <Card maxW="560px" width="100%" borderRadius="12px">
          <CardHeader
            background="#52e1de"
            borderTopRadius="12px"
            padding="10px"
          >
            <Text fontWeight="600" fontSize="18px">
              Stake Now
            </Text>
          </CardHeader>
          <CardBody>
            <Flex align="center" gap={5}>
              <Input
                placeholder="0"
                value={formData?.amount}
                onChange={(e) =>
                  setFormData({ ...formData, amount: e.target.value })
                }
              />
              <Input
                placeholder="0.000 LKD (Approx.)"
                value={calculateLkdValue()?.toString() + " LKD (Approx.)"}
                readOnly
              />
              <Input
                placeholder="0.000 USDT (Approx.)"
                value={calculateUSDTApprove()?.toString() + " USDT (Approx.)"}
                readOnly
              />
            </Flex>
            <Text mt="15px" fontSize="12px" fontWeight="500">
              Linkdao Price: {Number(lkdPrice)?.toFixed(2)}
            </Text>
            <Text mt="15px" fontSize="12px" fontWeight="500">
              Note: Min Stake is $25
            </Text>
            <Flex mt="20px" justify={"center"} align={"center"} gap={"10px"}>
              {usdtAllowance < calculateUSDTApprove() ? (
                <Button
                  onClick={() => {
                    
                      setIsLoading(true);
                      approveUsdt();
                    
                  }}
                  background="#52e1de"
                  borderRadius="8px"
                  height={"30px"}
                  disabled={isApproveUsdtLoading}
                >
                  {isApproveUsdtLoading ? <Spinner size="md" /> : ""}
                  Approve USDT
                </Button>
              ) : lkdAllowance < calculateLkdValue() ? (
                <Button
                  onClick={() => {
                    
                      setIsLoading(true);
                      approveLKD();
                   
                  }}
                  background="#52e1de"
                  borderRadius="8px"
                  height={"30px"}
                  disabled={isApproveLkdLoading}
                >
                  {isApproveLkdLoading ? <Spinner size="md" /> : ""}
                  Approve LKD
                </Button>
              ) : (
                <Button
                  onClick={async () => {
                    if (isConnected) {
                      if (Number(formData?.amount) > 24) {
                        setIsLoading(true);
                        if (
                          parseInt((lkdPrice * 100)?.toString()) !==
                          getLinkdaoPrice
                        ) {
                          let { data } = await axios.get(
                            "https://oracle.linkdao.cc/updateprice"
                          );
                          if (data?.status === "success") {
                            stake();
                          } else {
                            toast.warn(
                              "Something went wrong, Please try again"
                            );
                            setIsLoading(false);
                          }
                        }
                      } else {
                        toast.warn("Min stake $25");
                      }
                    } else {
                      toast.warn("Please connect wallet!");
                    }
                  }}
                  background="#52e1de"
                  borderRadius="8px"
                  height={"30px"}
                  disabled={isStakeLoading}
                >
                  {isStakeLoading ? <Spinner size="md" /> : ""}
                  Stake
                </Button>
              )}
            </Flex>
            
            <Packages />
          </CardBody>
        </Card>
      </Flex>
    </Box>
  );
}

export default App;
